// @ts-check
import { addMonths, getPeriodStart, isBefore } from '@/libs/date.js';
import { set, getSoftware } from '@/libs/vuex.js';
import { initState, initUserState } from './state.js';

export default {
    resetState(state) {
        Object.assign(state, initState());
    },
    resetUserState(state) {
        Object.assign(state, initUserState());
    },
    setVw: set('vw'),
    setHasToken: set('hasToken', true),
    setIsInitialized: set('isInitialized', true),
    setUpgradeMessage: set('upgradeMessage'),
    setCustomerUserId: set('customerUserId'),
    setCurrentUser: set('currentUser'),
    setCurrentPlan: set('currentPlan'),
    setCurrentPlanFetchDone: set('currentPlanFetchDone'),
    setServiceName(state, isAWA) {
        state.serviceName = isAWA ? 'AWA' : 'Gtax';
    },
    setUserCalcType(state, calcType) {
        state.currentUser.calc_type = calcType;
    },
    setMailMagazineFlag(state, MailMagazineFlag) {
        state.currentUser.mailmagazine_flag = MailMagazineFlag;
    },
    setIsCpta(state, isCpta) {
        state.currentUser.cookie_params.is_cpta = isCpta;
    },
    setCptaOfficeStatus(state, cptaOfficeStatus) {
        state.currentUser.cookie_params.cpta_office_status = cptaOfficeStatus;
    },
    setErrorMessage: set('errorMessage'),
    setRegistrationApplied(state, value) {
        state.currentUser.cookie_params.cpta_office_status.registration_applied = value;
    },
    setTutorialState: set('tutorials'),
    initPeriod(state, { usersYear, isBusiness }) {
        const now = new Date();
        let year = now.getFullYear();
        // 期首日から3ヶ月経過していなくても、その年度の機種残高は登録できるようにする
        const beginningBalanceYear = year;

        // 個人版のみの対応
        if (!isBusiness && !usersYear.oldest_open) {
            // 今年（今年度ではなく）の期首日から3ヶ月経過していなければ、初期表示年度を昨年に設定する。
            const termStart = getPeriodStart(year, usersYear.accounting_month);
            const threeMonthsFromTermStart = addMonths(termStart, 3);
            if (isBefore(now, threeMonthsFromTermStart)) {
                year--;
            }
        }

        const oldestOpenYear = usersYear.oldest_open ?? year;

        state.period = {
            loaded: true,
            current: {
                year: oldestOpenYear,
                closingStatus: null,
            },
            accountingMonth: usersYear.accounting_month,
            closingTerm: usersYear.closing_term,
            valuationTerm: usersYear.valuation_term,
            isCloseValuate: !!usersYear.is_close_valuate,
            latest: oldestOpenYear,
            max_reachable_year: oldestOpenYear,
            oldest: oldestOpenYear,
            oldestOpen: oldestOpenYear,
            beginningBalanceYear,
        };
    },
    setPeriodLoaded(state, bool) {
        state.period.loaded = bool;
    },
    setLatestClosedTerm(state, { label, start_at, end_at }) {
        state.latestClosedTerm = {
            year: Number(label.substring(0, 4)) || null,
            startAt: start_at || null,
            endAt: end_at || null,
        };
    },
    setYear(state, year) {
        state.period.current.year = year;
    },
    setAccountingMonth(state, accountingMonth) {
        state.period.accountingMonth = accountingMonth;
    },
    setClosingTerm(state, closingTerm) {
        state.period.closingTerm = closingTerm;
    },
    setIsCloseValuate(state, isCloseValuate) {
        state.period.isCloseValuate = isCloseValuate;
    },
    setClosingStatus(state, closingStatus) {
        state.period.current.closingStatus = closingStatus;
    },
    setRangeOfPeriod(state, { latest, oldest, oldestOpen, max_reachable_year }) {
        state.period.latest = latest;
        state.period.oldest = oldest;
        state.period.oldestOpen = oldestOpen;
        state.period.max_reachable_year = max_reachable_year;
        state.period.beginningBalanceYear = oldest;
    },
    setCalcState(state, calcState) {
        Object.assign(state.calcState, calcState);
    },
    setCalcStateProcessing(state) {
        state.calcState = {
            status: 'processing',
            profit: null,
            message: null,
        };
    },
    setCalcStateUnexecuted(state) {
        state.calcState = {
            status: 'unexecuted',
            profit: null,
            message: null,
        };
    },
    setZeroTradeRecord: set('zeroTradeRecord'),
    setMinusBalance: set('minusBalance'),
    setCheckCalcStateRunning: set('isCheckCalcStateRunning'),
    setIsSetAccountingRule(state, boolean) {
        state.currentUser.is_set_accounting_rule = boolean;
    },
    setAccountingSoftware(state, softwareKey) {
        state.accountingSoftware = getSoftware(softwareKey);
    },
    setTradeValidator: set('tradeValidator'),
    setOrderTypes: set('orderTypes'),
    setExchangers: set('exchangers'),
    setUnimportedTradeCount: set('unimportedTradeCount'),
    setPendingTradeCount: set('pendingTradeCount'),
    setPendingDefiCount: set('pendingDefiCount'),
    setAccountingTransferCount: set('accountingTransferCount'),
    setTxConfirmationCount: set('txConfirmationCount'),
    setFileAlerts: set('fileAlerts'),
    setExchangerUsage: set('exchangerUsage'),
    setJournalSchemaMap: set('journalSchemaMap'),
    setJournalRuleSchemaMap: set('journalRuleSchemaMap'),
    setServiceNotice: set('serviceNotice'),
    setNews: set('news'),
    setLastViewedNewsDate: set('lastViewedNewsDate'),
    showProhibitedTooltip: set('prohibitedTooltip'),
    hideProhibitedTooltip: (state) => {
        state.prohibitedTooltip = undefined;
    },
};
