<template>
    <BaseDialog
        :visible="!!getUpgradeMessage"
        title="プランアップグレード"
        width="780px"
        submitText="プラン・お支払い情報へ"
        cancelText="戻る"
        @submit="submit"
        @close="close"
    >
        <div class="container">
            <h3>
                <WarningIcon theme="primary" style="width: 2.6rem" />
                <span>プランアップグレードのご案内</span>
            </h3>
            <div class="content" v-html="getUpgradeMessage" />

            <section v-if="isTrialTarget" class="trial">
                <div class="title">
                    <img src="@/assets/images/crown-icon.png" alt="" />
                    <h4>今だけ！２週間無料トライアル実施中！</h4>
                </div>

                <h5>有料プランを無料でお試しいただけるキャンペーンを実施中です！</h5>
                <ul>
                    <li>トライアル中はいつでもプラン変更・解約可能です</li>
                    <li>トライアル開始から2週間後に料金が請求されます</li>
                    <li>お申し込みは2/24（月）まで。申し込み当日から2週間無料トライアルを利用できます</li>
                </ul>
            </section>
        </div>
    </BaseDialog>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseDialog from '@/components/BaseDialog.vue';
import WarningIcon from '@/components/icons/Warning.vue';

export default {
    components: {
        BaseDialog,
        WarningIcon,
    },
    computed: {
        ...mapGetters(['getUpgradeMessage', 'isTrialTarget']),
    },
    methods: {
        close() {
            this.$store.commit('setUpgradeMessage', undefined);
        },
        submit() {
            this.$router.push('/subscriptions/payments');
            this.$store.commit('setUpgradeMessage', undefined);
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    margin: 0 auto;
    max-width: min(60rem, 100%);
    padding: 12px 0 12px;
    line-height: 1.7;

    h3 {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 2rem;
        font-weight: bold;
        color: #4694d8;
    }
    .content {
        margin-top: 32px;
        font-size: 1.5rem;

        ::v-deep p {
            margin: 0;
            & + p {
                margin-top: 1rem;
            }
        }
    }

    .trial {
        max-width: 52rem;
        margin: 2rem auto 0;
        padding: 1.4rem 2.4rem;
        border: 2px solid #4694d8;
        border-radius: 8px;

        h4,
        h5 {
            margin: 0;
        }

        .title {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.4em;

            color: #4694d8;
            font-size: 1.8rem;

            img {
                width: 2em;
                height: 2em;
            }
        }

        h5 {
            margin-top: 1rem;
            font-size: 1.5rem;
        }
    }
}
</style>
