// @ts-check
import { isRestrictedPath } from '@/libs/const/userRestriction.js';

export default async ({ app, store, route, redirect }) => {
    if (!store.getters.isLoggedIn) {
        try {
            await store.dispatch('authenticate', { app });
        } catch (_) {
            return redirect('/signin');
        }
    }

    if (store.getters.isRestrictedUser && isRestrictedPath(route.path)) {
        return redirect(store.getters.getHomeURL);
    }
    if (route.path.startsWith('/import/api') && !store.state.currentUser.feature_toggle?.api_key_register) {
        return redirect('/import/file/exchangers');
    }
    // 個人版は/cpta/*に用はないのでリダイレクト
    if (store.getters.getIsRegularUser && route.path.startsWith('/cpta')) {
        return redirect(store.getters.getHomeURL);
    }
    // /subscriptions以下のページは、個人版/法人版のみアクセス可能。ただし、ごく少数の永久無料ユーザーは除く
    if (
        route.path.startsWith('/subscriptions') &&
        (store.getters.getCurrentUser.is_free || (!store.getters.getIsCorporate && !store.getters.getIsRegularUser))
    ) {
        return redirect(store.getters.getHomeURL);
    }

    if (store.getters.getIsCpta) {
        if (route.path.startsWith('/cpta/customers') && store.getters.isInitialized) {
            return store.dispatch('initCptaUser');
        }
        if (isAccessibleWithoutUserId(route.path)) return;
        if (!store.getters.getCustomerUserId) {
            try {
                await store.dispatch('retrieveCustomerId');
            } catch (_) {
                return redirect('/cpta/customers');
            }
        }
    }

    if (!store.getters.isInitialized) {
        const { required } = await store.dispatch('initAppData');
        await required;
    }

    if (!store.getters.getIsBusiness) return;

    if (store.getters.getIsEnterprise) {
        if (route.path.indexOf('/cpta/payment') !== -1) {
            return redirect('/import/file/exchangers');
        }
    }

    // お試し期間が終了している場合は、新規登録画面にリダイレクトし、関係ないページへのアクセスを制限する
    if (store.getters.getCptaOfficeStatus.paid_status === 'trial_expired') {
        if (
            route.path !== '/cpta/payment/apply' &&
            route.path !== '/subscriptions/apply' &&
            route.path !== '/user-setting' &&
            route.path !== '/cpta/payment/withdraw'
        ) {
            return redirect('/cpta/payment/apply');
        }
    }

    if (
        store.getters.getCptaOfficeStatus.registration_applied &&
        store.getters.getCptaOfficeStatus.paid_status !== 'withdrawed'
    ) {
        // 登録済であれば新規登録画面から支払い情報画面にリダイレクトする
        if (route.path === '/cpta/payment/apply') {
            return redirect('/subscriptions/payments');
        }
        // お試し期間中（未支払い）であれば支払い情報画面から新規登録画面にリダイレクトする
    } else if (route.path === '/subscriptions/payments') {
        return redirect('/cpta/payment/apply');
    }
};

const isAccessibleWithoutUserId = (path) => path.startsWith('/cpta/') || path.startsWith('/user-setting');
