<template>
    <div v-if="isVisible" class="service-notice" :class="notice.type">
        <i v-if="notice.type === 'danger'" class="icon-exclamation" />
        <span v-html="notice.message" />
        <button class="close-btn" @click="close"><i class="el-icon-close" /></button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { clientStorage, STORAGE_KEYS } from '@/libs/storage.js';
import { addDays, differenceInDays, formatDateJP } from '@/libs/date.js';

export default {
    name: 'ServiceNotice',
    data() {
        return {
            isHidden: false,
        };
    },
    computed: {
        ...mapGetters([
            'isLoggedIn',
            'getServiceNotice',
            'getIsRegularUser',
            'getIsCpta',
            'getIsDapps',
            'getIsCreator',
            'getIsCorporate',
            'getCurrentUser',
        ]),
        // データが削除される予定のユーザーには、設定された通知があったとしてもデータ削除の期限を表示する。
        deletionDate() {
            if (this.getCurrentUser?.data_will_be_deleted_in == null) return undefined;
            const date = addDays(new Date(), this.getCurrentUser.data_will_be_deleted_in);
            return formatDateJP(date);
        },
        deletionNotice() {
            if (!this.deletionDate) return undefined;

            return {
                id: 9998,
                type: 'danger',
                message: `${this.deletionDate}に全てのデータが削除されます。詳しくは<a href="/subscriptions/payments">こちら</a>をご確認ください。`,
            };
        },
        notice() {
            if (this.deletionNotice) {
                // リンクの遷移先である支払画面では非表示
                if (this.$route.path === '/subscriptions/payments') return undefined;
                return this.deletionNotice;
            }
            if (!this.getServiceNotice) return undefined;
            const { message, type, targetVersions } = this.getServiceNotice;
            if (!this.isTargetted(targetVersions)) return undefined;
            return { message, type };
        },
        isVisible() {
            return !this.isHidden && !!this.notice;
        },
        versionMap() {
            return new Map([
                ['*', true],
                ['regular', this.getIsRegularUser],
                ['cpta', this.getIsCpta],
                ['awa', this.getIsDapps],
                ['creator', this.getIsCreator],
                ['corporate', this.getIsCorporate],
            ]);
        },
    },
    mounted() {
        const saved = clientStorage.get(STORAGE_KEYS.hiddenServiceNotice);
        if (!saved) return;

        // 一度非表示にした通知は、1日経過するまで再表示しない
        if (saved.id === this.getServiceNotice.id && differenceInDays(saved.timestamp, Date.now()) < 1) {
            this.isHidden = true;
            return;
        }

        // １日以上経過、または別の通知になった場合は非表示状態をリセット
        clientStorage.remove(STORAGE_KEYS.hiddenServiceNotice);
    },
    methods: {
        isTargetted(versions = []) {
            // 全対象の場合は常に表示
            if (versions.includes('*')) return true;

            // 全対象以外の場合はログインしていない場合は表示しない
            if (!this.isLoggedIn) return false;

            // 指定のバージョンが一つでもユーザーのバージョンとマッチすれば表示
            return versions.some((v) => this.versionMap.get(v));
        },
        close() {
            this.isHidden = true;
            clientStorage.set(STORAGE_KEYS.hiddenServiceNotice, {
                id: this.getServiceNotice.id,
                timestamp: Date.now(),
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.service-notice {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--notice-color);
    border-bottom: 1px solid var(--notice-bdc, var(--notice-color));
    padding: 12px 10%;
    background: var(--notice-bgc);
    word-break: break-word;
    font-weight: 700;
    line-height: 1.5;

    &.info {
        --notice-color: #444;
        --notice-bgc: #d1e8ef;
        --notice-bdc: transparent;
    }
    &.danger {
        --notice-color: #e23434;
        --notice-bgc: #fef3f3;

        ::v-deep a {
            color: inherit;
        }
    }

    .icon-exclamation {
        margin-right: 4px;
        &::before {
            border-radius: 50%;
            transform: scale(0.8);
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            width: 24px;
            height: 24px;
        }
    }

    .close-btn {
        position: absolute;
        right: 5%;
        top: 50%;
        translate: 50% -50%;
        flex-shrink: 0;
        display: block;
        height: 90%;
        aspect-ratio: 1;
        border: none;
        border-radius: 50%;
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.2s;
        &:hover,
        &:focus {
            background-color: #fff5;
        }
        &:active {
            background-color: #fff8;
        }

        .el-icon-close {
            color: inherit;
            font-size: 1.5rem;
            font-weight: 700;
        }
    }

    ::v-deep {
        a {
            text-decoration: underline;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }
    }
}
</style>
