// @ts-check

export const initState = () => ({
    vw: window.innerWidth,
    hasToken: false,
    serviceName: 'Gtax', // 'Gtax' || 'AWA'
    tutorials: null,
    errorMessage: {},
    upgradeMessage: undefined,
    tradeValidator: null,
    orderTypes: [],
    exchangers: [],
    fileAlerts: [],
    journalSchemaMap: {},
    journalRuleSchemaMap: {},
    serviceNotice: undefined,
    news: [],
    lastViewedNewsDate: undefined,
    ...initUserState(),
});

export const initUserState = () => ({
    isInitialized: false,
    customerUserId: null,
    currentUser: {
        id: null,
        email: '',
        customer_name: '',
        is_internal: false,
        calc_type: '',
        cookie_params: {
            account_type: null,
            is_business: false,
            is_cpta: false,
            cpta_status: {},
            cpta_office_status: {},
            plan: '',
            registration_applied: false,
        },
        is_corporate_display: false,
        is_set_accounting_rule: false,
        has_custody: false,
        created_at: '',
        feature_toggle: {
            api_key_register: false,
        },
    },
    currentPlan: undefined,
    currentPlanFetchDone: new Promise(() => {}),
    period: {
        loaded: false,
        current: {
            year: null,
            closingStatus: null,
        },
        accountingMonth: null,
        closingTerm: null,
        valuationTerm: null,
        isCloseValuate: null,
        latest: null,
        max_reachable_year: null,
        oldest: null,
        oldestOpen: null,
        beginningBalanceYear: null,
    },
    latestClosedTerm: {
        /** @type {number|null} */
        year: null,
        /** @type {string|null} */
        startAt: null,
        /** @type {string|null} */
        endAt: null,
    },
    calcState: {
        status: 'processing',
        profit: null,
        message: null,
    },
    isCheckCalcStateRunning: false,
    zeroTradeRecord: false,
    minusBalance: [],
    unimportedTradeCount: { monthly: [], yearly: [], total: 0 },
    pendingTradeCount: { monthly: [], yearly: [], total: 0 },
    pendingDefiCount: { monthly: [], yearly: [], total: 0 },
    accountingTransferCount: null,
    txConfirmationCount: null,
    exchangerUsage: {},
    accountingSoftware: undefined,
    // 利用制限のツールチップ表示用。
    // { event: HTMLEvent, position: 'left' | 'right', sideOffset: number }
    prohibitedTooltip: undefined,
});

export default initState;
